@import '../../assets/theme.scss';

.home {
  padding: 3rem 2rem;
  box-sizing: border-box;
  height: 100vh;
  background-color: $primary;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 10%;

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 300px;
      min-height: 300px;

      .profile-picture {
        width: 80%;
        height: auto;

        padding: 1rem;

        border-radius: 50%;
      }
    }

    p {
      text-align: center;
      margin: 8px 0;
    }
  }
}

@media screen and (min-width: 550px) {
  .home {
    .profile {
      margin-top: 6rem;

      p {
        margin: 1.2rem 0;
        font-size: 15pt;
      }
    }
  }
}
