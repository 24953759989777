@import '../../assets/theme.scss';

.about {
  padding: 3rem 2rem;
  box-sizing: border-box;
  height: 100vh;
  background-color: $primary;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 10%;

    h1 {
      margin: 0 auto 0 0;
    }

    .about-text {
      display: flex;
      justify-content: center;

      p {
        font-family: "Open Sans";
        font-size: 12pt;

        max-width: 470px;

        b {
          font-weight: 600;
        }
      }

      a {
        text-decoration: underline;
      }
    }

    .social {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 12px;

      margin-top: 2rem;
    }

    .resume {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;

      margin-top: 2rem;

      font-family: "Open Sans";
      font-size: 16pt;
    }
  }
}

@media screen and (min-width: 550px) {
  .about {
    .content {
      margin-top: 6rem;

      h1 {
        margin: 0 auto;
      }

      .about-text {
        p {
          font-size: 16pt;

          max-width: 670px;
        }
      }

      .social {
        gap: 24px;

        margin-top: 4rem;
      }
    }
  }
}
