@import '../../assets/theme.scss';

.projects {
  padding: 3rem 1rem;
  box-sizing: border-box;
  background-color: $primary;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .projects-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    margin-top: 2rem;

    .attributions {
      font-family: "Open Sans";
      font-size: 12pt;
    }
  }
}
