@import '../../assets/theme.scss';

.navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  a {
    cursor: pointer;
    font-size: 15pt;
    transition: .5s;
  }

  a:hover {
    box-shadow: 0 3px 0 $white;
    animation: upDown 1.4s ease-in-out infinite;
  }
}

@media screen and (min-width: 550px) {
  .navbar {
    flex-direction: row;
    gap: 30px;

    a {
      font-size: 20pt;
    }
  }
}

@keyframes upDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}
