@import '../../assets/theme.scss';

.app {
  color: $white;

  overflow: hidden;

  a {
    color: $white;
    text-decoration: none;
    outline: none;
  }

  .page-container {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
  }

  .page {
    .layer {
      position: absolute;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: -100vh;
      background: $white;

      z-index: 2;

      opacity: 0;
    }
  }

  .page-appear {
    position: absolute;
    width: 100vw;

    .layer {
      opacity: 1;
      top: -100vh;
      transform: translateY(100vh);
    }

    .page-container {
      opacity: 0;
    }
  }

  .page-appear-active {
    .layer {
      opacity: 1;
      transform: translateY(200vh);

      transition: all 0.6s cubic-bezier(0.6, 0.05, 0.4, 1);
    }

    .page-container {
      opacity: 1;
      transition: all 0.3s ease-in 0.3s;
    }
  }

  .page-appear-done {
    position: relative;

    .layer {
      opacity: 0;
      transform: translateY(0);
    }
  }

  .page-enter {
    opacity: 0;
    // It overflows :sob:
    // transform: scale(1.1);

    position: absolute;
    width: 100vw;
    top: 0;
  }

  .page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 0.6s;
  }

  .page-enter-done {
    position: relative;
  }

  .page-exit {
    opacity: 0;
    transform: scale(1);
  }

  .page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: all 0.3s;
    display: none;
  }
}
